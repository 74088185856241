import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import {Location} from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import * as global from '../../global';
@Component({
  selector: 'app-custom-pdf-dialog',
  templateUrl: './custom-pdf-dialog.component.html',
  styleUrls: ['./custom-pdf-dialog.component.css']
})
export class CustomPdfDialogComponent implements OnInit {
  census_id: any;
  census_columns: any;
  global = global;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private _location: Location,
    private route: ActivatedRoute,
    private _route: Router
  ) { 
    this.currentUser = this.authService.currentUser;
  }

  currentUser: any;
  census: Boolean = false;
  data: any = {
  orientation :  "",
  fontSize:  "",
  column: {}
  }
  allcolumns=['operations','name','note','comments_count','admission_date','date_of_birth','discharge','location','payer','mrn','last_seen','pcc_patient_diagnosis','primary_secondary_payor','pcp'];

  async ngOnInit(): Promise<void> {
    if(this.route.snapshot.params.census) {
      this.census = true;
      this.census_id = this.route.snapshot.params.census;
    }
    if(!((this.currentUser.other_settings?.census_columns)?this.currentUser.other_settings?.census_columns?.length>0:false)){
      await this.setDefaultcols();
    }
    this.census_columns=(this.currentUser.other_settings?.census_columns)?this.currentUser.other_settings?.census_columns:this.allcolumns;
   this.data.orientation = this.currentUser.other_settings?.census_pdf_orientation;
   this.data.fontSize = this.currentUser.other_settings?.census_pdf_fontSize;
   this.data.column = this.currentUser.other_settings?.census_pdf_column;
   if(this.currentUser.other_settings.census_pdf_fontSize === undefined && this.currentUser.other_settings.census_pdf_orientation === undefined) {
    this.data.orientation = "landscape";
    this.data.fontSize = "10";
   }
  //  console.log("census_pdf_fontSize",this.currentUser.other_settings.census_pdf_fontSize);
  //  console.log("census_pdf_orientation",this.currentUser.other_settings.census_pdf_orientation);
  }

  handleCheckboxes($event) {
    // console.log($event.source);
    if($event.source.checked) {
      let key = $event.source.value.toString();
      let value = $event.source.value;
      let obj = {};
      obj[key] = value;
      this.data.column = {...this.data.column, ...obj};
     
      // this.data.column[$event.source.value] = $event.source.value;
      console.log("thi",this.data.column);
    }
    if(!$event.source.checked && this.data.column.hasOwnProperty($event.source.value)) {
      delete this.data.column[$event.source.value];
    }
  }



  setDefault() {
    
      let message = "";
      let flag = 0;
      if(this.data.fontSize === undefined || this.data.fontSize === "") {
        // console.log(this.data.fontSize);
        message += "Font Size not selected\n";
        flag++;
      }
      if(this.data.orientation === undefined || this.data.orientation === "") {
        // console.log(this.data.orientation);
        message += "orientation not selected\n";
        flag++;

      }
      if(this.data.column === undefined || Object.values(this.data.column).length === 0) {
        // console.log(this.data?.column);
        message += "column not selected";
        flag++;
      }
      if(flag > 0 ) {
        alert(message)
        if(this.census){
          this._route.navigate([`/census/${this.census_id}`]);
        }else{
          // this._route.navigate(['/dashboard']);
          this.toastr.success('Changed Settings Successfully', 'Success');
        }
      }
  
    if(this.data.fontSize !== "" && this.data.orientation !== "" && Object.values(this.data.column).length > 0) {
    this.currentUser.other_settings.census_pdf_orientation = this.data.orientation;
    this.currentUser.other_settings.census_pdf_fontSize = this.data.fontSize;
    this.currentUser.other_settings.census_pdf_column = this.data.column;
    //
      this.authService.setOtherUserSettings(this.currentUser.other_settings).subscribe(res => {
        if (this.census) {
          this._route.navigate([`/census/${this.census_id}`]);
        } else {
          // this._route.navigate(['/dashboard']);
          this.toastr.success('Changed Settings Successfully', 'Success');
        }
        // this._location.back();
      if(res.data) {
        this.currentUser = {
          ...this.currentUser,
          other_settings: res.data.other_settings
      }
      this.toastr.success('Changed Settings Successfully', 'Success');
      }
    });
  }
    
  }

  handlecensus_columnsCheckboxes($event) {

    if($event.source.checked) {

      let value = $event.source.value.toString();
      let index=this.census_columns?.findIndex(e=>e?.toString()==value?.toString());
      if(index>=0){
      }else{
        this.census_columns.push(value);
      }
    //  console.log("thi check true", this.census_columns);


    }else if(!$event.source.checked){

      let value = $event.source.value.toString();
      this.census_columns=this.census_columns?.filter(e=>e!=value);
    //  console.log("thi uncheck",this.census_columns);


    }
   
  }

  checkExistence(value){
    //console.log("this.census_columns",this.census_columns);
    return this.census_columns?.findIndex(e=>e?.toString()==value?.toString())>=0?true:false;
  }

  async setDefaultcols(){

    this.currentUser.other_settings.census_columns = this.allcolumns;
    console.log("this.census_columns def: ", this.allcolumns);
      this.authService.setOtherUserSettings(this.currentUser.other_settings).subscribe(res => {
        // if (this.census) {
        //   this._route.navigate([`/census/${this.census_id}`]);
        // } else {
        //  this.toastr.success('Changed Settings Successfully', 'Success');
       // }
        
        
      if(res.data) {
        this.currentUser = {
          ...this.currentUser,
          other_settings: res.data.other_settings
      }
      //this.toastr.success('Changed Settings Successfully', 'Success');
      }
    });

  }

  setcensus_columns(){


      this.currentUser.other_settings.census_columns = this.census_columns;
      console.log("this.census_columns : ", this.census_columns);
        this.authService.setOtherUserSettings(this.currentUser.other_settings).subscribe(res => {
          // if (this.census) {
          //   this._route.navigate([`/census/${this.census_id}`]);
          // } else {
          //  this.toastr.success('Changed Settings Successfully', 'Success');
         // }
          
          
        if(res.data) {
        //   this.currentUser = {
        //     ...this.currentUser,
        //     other_settings: res.data.other_settings
        // }
        this.currentUser.other_settings= res.data.other_settings;
      
        this.toastr.success('Changed Settings Successfully', 'Success');
        }
      });
    
  }

}
