<div class="flex flex-grow justify-around items-center">
    <div class="flex flex-col flex-grow justify-center items-center">

        <div class="flex flex-col justify-center items-center">
            <h2 mat-dialog-title style="justify-content: center;">Print View: Census Custom PDF</h2>
        </div>
        <br>
        <mat-dialog-content>

            <div class="flex flex-col justify-around items-center">
                <div class="flex justify-center items-center gap-2">
                    <mat-form-field style="color: black !important;">
                        <mat-label>Select Orientation</mat-label>
                        <mat-select [(ngModel)]="data.orientation" name="orientation">
                            <mat-option value="portrait">
                                Portrait
                            </mat-option>
                            <mat-option value="landscape">
                                Landscape
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field style="color: black !important;">
                        <mat-label>Select Font Size</mat-label>
                        <mat-select [(value)]="data.fontSize" name="orientation">
                            <mat-option value="6">
                                6
                            </mat-option>
                            <mat-option value="8">
                                8
                            </mat-option>
                            <mat-option value="9">
                                9
                            </mat-option>
                            <mat-option value="10">
                                10
                            </mat-option>
                            <mat-option value="11">
                                11
                            </mat-option>
                            <mat-option value="12">
                                12
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <h3>Select Columns to export</h3>
                <div class="flex justify-center items-center gap-9">
                    <div class="flex flex-col justify-between items-start">
                        <mat-checkbox value="Name" [checked]="data.column?.Name" (change)="handleCheckboxes($event)">
                            Name
                        </mat-checkbox>
                        <mat-checkbox value="A/D" [checked]="data.column?.hasOwnProperty('A/D')"
                            (change)="handleCheckboxes($event)">
                            A/D
                        </mat-checkbox>
                    </div>
                    <div class="flex flex-col justify-between items-start">
                        <mat-checkbox value="DOB" [checked]="data.column?.DOB" (change)="handleCheckboxes($event)">
                            DOB
                        </mat-checkbox>
                        <mat-checkbox value="Room No" [checked]="data.column?.hasOwnProperty('Room No')"
                            (change)="handleCheckboxes($event)">
                            Room No
                        </mat-checkbox>
                    </div>
                    <div class="flex flex-col justify-between items-start">
                        <mat-checkbox value="D/C" [checked]="data.column?.hasOwnProperty('D/C')"
                            (change)="handleCheckboxes($event)">
                            D/C
                        </mat-checkbox>
                        <mat-checkbox value="Payor" [checked]="data.column?.Payor" (change)="handleCheckboxes($event)">
                            Payor
                        </mat-checkbox>
                    </div>

                    <div class="flex flex-col justify-between items-start">
                        <mat-checkbox value="Comments" [checked]="data.column?.Comments"
                            (change)="handleCheckboxes($event)">
                            Comments
                        </mat-checkbox>

                        <mat-checkbox value="Last Seen" [checked]="data.column?.hasOwnProperty('Last Seen')"
                            (change)="handleCheckboxes($event)">
                            Last Seen
                        </mat-checkbox>

                    </div>
                    <div class="flex flex-col justify-between items-start">
                        <mat-checkbox value="pdx" [checked]="data.column?.pdx" (change)="handleCheckboxes($event)">
                            PDX
                        </mat-checkbox>
                        <mat-checkbox value="pcp" [checked]="data.column?.pcp" (change)="handleCheckboxes($event)">
                            PCP
                        </mat-checkbox>
                    </div>
                    <div class="flex flex-col justify-between items-start" *ngIf="currentUser.portal_type === global.PORTAL_TYPE.NON_WC">
                        <mat-checkbox value="Medication" [checked]="data.column?.hasOwnProperty('Medication')"
                            (change)="handleCheckboxes($event)">
                            Medications
                        </mat-checkbox>

                        <mat-checkbox value="mrn" [checked]="data.column?.mrn" (change)="handleCheckboxes($event)">
                            MRN
                        </mat-checkbox>
                    </div>
                    <div class="flex flex-col justify-between items-start">
                        <mat-checkbox value="Last CPT" [checked]="data.column?.hasOwnProperty('Last CPT')"
                            (change)="handleCheckboxes($event)">
                            Last CPT
                        </mat-checkbox>
                    </div>

                </div>



            </div>
            <br>
        </mat-dialog-content>

        <br>

        <div class="flex justify-center items-center">
            <button class="dn-button-primary" mat-raised-button type="button" (click)="setDefault()">Save</button>
        </div>

    </div>


    <div class="flex flex-col flex-grow justify-center items-center">

        <div class="flex flex-col justify-center items-center">
            <h2 mat-dialog-title style="justify-content: center;">Census Columns To View</h2>
        </div>
        <br>

        <mat-dialog-content>

            <div class="flex flex-col justify-around items-center">

                <h3>Select Columns to Display</h3>
                <div class="flex justify-center items-center gap-9">
                    <div class="flex flex-col justify-between items-start">
                        <mat-checkbox value="comments_count" [checked]="checkExistence('comments_count')"
                            (change)="handlecensus_columnsCheckboxes($event)">
                            Comments
                        </mat-checkbox>
                        <mat-checkbox value="admission_date" [checked]="checkExistence('admission_date')"
                            (change)="handlecensus_columnsCheckboxes($event)">
                            A/D
                        </mat-checkbox>
                        <mat-checkbox value="date_of_birth" [checked]="checkExistence('date_of_birth')"
                            (change)="handlecensus_columnsCheckboxes($event)">
                            DOB
                        </mat-checkbox>
                        <mat-checkbox value="mrn" [checked]="checkExistence('mrn')"
                            (change)="handlecensus_columnsCheckboxes($event)">
                            MRN
                        </mat-checkbox>
                    </div>

                    <div class="flex flex-col justify-between items-start">
                        <mat-checkbox value="discharge" [checked]="checkExistence('discharge')"
                            (change)="handlecensus_columnsCheckboxes($event)">
                            D/C
                        </mat-checkbox>
                        <mat-checkbox value="location" [checked]="checkExistence('location')"
                            (change)="handlecensus_columnsCheckboxes($event)">
                            Room No
                        </mat-checkbox>
                        <mat-checkbox value="payer" [checked]="checkExistence('payer')"
                            (change)="handlecensus_columnsCheckboxes($event)">
                            Payor
                        </mat-checkbox>
                        <mat-checkbox value="primary_secondary_payor" [checked]="checkExistence('primary_secondary_payor')"
                        (change)="handlecensus_columnsCheckboxes($event)">
                           Primary and Secondary Payor
                       </mat-checkbox>
                        <mat-checkbox value="last_cpt" [checked]="checkExistence('last_cpt')" (change)="handlecensus_columnsCheckboxes($event)" *ngIf="currentUser.user_type != 'snf_wc_nurse'">
                            Last CPT
                        </mat-checkbox>
                        <mat-checkbox value="patient-consent-status"
                            [checked]="checkExistence('patient-consent-status')"
                            (change)="handlecensus_columnsCheckboxes($event)"
                            *ngIf="this.currentUser.company_type == global.COMPANY_TYPE.NURSING">
                            CCM Consent
                        </mat-checkbox>
                    </div>

                    <div class="flex flex-col justify-between items-start">
                        <mat-checkbox value="last_seen" [checked]="checkExistence('last_seen')"
                            (change)="handlecensus_columnsCheckboxes($event)">
                            Last Seen
                        </mat-checkbox>
                        <mat-checkbox value="pcc_patient_diagnosis" [checked]="checkExistence('pcc_patient_diagnosis')"
                            (change)="handlecensus_columnsCheckboxes($event)">
                            PDX
                        </mat-checkbox>
                        <mat-checkbox value="pcp" [checked]="checkExistence('pcp')"
                            (change)="handlecensus_columnsCheckboxes($event)">
                            PCP
                        </mat-checkbox>
                        <mat-checkbox value="face_sheet" [checked]="checkExistence('face_sheet')"
                            (change)="handlecensus_columnsCheckboxes($event)">
                            Facesheet
                        </mat-checkbox>
                        <mat-checkbox value="is_patient_pcm_consent"
                            [checked]="checkExistence('is_patient_pcm_consent')"
                            (change)="handlecensus_columnsCheckboxes($event)"
                            *ngIf="this.currentUser.company_type == global.COMPANY_TYPE.NURSING">
                            PCM Consent
                        </mat-checkbox>
                    </div>

                    <div class="flex flex-col justify-between items-start">
                        <mat-checkbox value="self" *ngIf="currentUser.portal_type === global.PORTAL_TYPE.NON_WC" [checked]="checkExistence('self')"
                            (change)="handlecensus_columnsCheckboxes($event)">
                             Last seen - self
                        </mat-checkbox>
                        <mat-checkbox currentUser.company_type === value="providers" *ngIf="currentUser.portal_type === global.PORTAL_TYPE.NON_WC" [checked]="checkExistence('providers')"
                            (change)="handlecensus_columnsCheckboxes($event)">
                            Last seen - Other providers
                        </mat-checkbox>
                        <mat-checkbox value="operations" [checked]="true" [disabled]="true">
                            Actions
                        </mat-checkbox>
                        <mat-checkbox value="name" [checked]="true" [disabled]="true">
                            Name
                        </mat-checkbox>
                        <mat-checkbox value="note" [checked]="true" [disabled]="true">
                            Note
                        </mat-checkbox>
                    </div>
                </div>



            </div>
            <br>
        </mat-dialog-content>

        <br>

        <div class="flex justify-center items-start">
            <button class="dn-button-primary" mat-raised-button type="button"
                (click)="setcensus_columns()">Save</button>
        </div>


    </div>
</div>